<template>
  <div>
    <!-- 首页 -->
    <!-- banner区域 -->
    <div class="banner_box">
      <div class="type_area">
        <div class="notice_box" v-if="noticeShow">
          <i class="el-icon-warning info"></i>
          <el-carousel style="width: 1020px;" indicator-position="none" height="42px" direction="vertical" >
            <el-carousel-item v-for="item in noticeList" :key="item.id">
              <div style="line-height: 42px;"><span>【{{item.title}}】</span>{{item.content}}</div>
            </el-carousel-item>
          </el-carousel>
          <div class="close" @click="closeNotice">
            <i class="el-icon-close"></i>
          </div>
        </div>
      </div>
      <el-carousel trigger="click" :height="bannerHeight+'px'">
        <el-carousel-item v-for="item in bannerList" :key="item.id">
          <img class="banner_img" style="width: 100%;height:20.83vw;min-height: 250px;cursor: pointer;" ref="bannerHeight" @click="goToExternalPage(item.url)" :src="item.image" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="type_area index_box">
      <!-- 广告区域 -->
      <div class="advert_box">
        <div class="advert_top">
          <div class="advert_item" v-for="(item,index) in indexTopicList" :key="item.id">
            <img v-if="index<3" @click="goTosubPage(item.url)" :src="item.image" alt="">
          </div>
        </div>
        <div class="advert_bottom">
          <el-carousel trigger="click" height="100px" indicator-position="none">
            <el-carousel-item v-for="item in floorAdList" :key="item.id">
              <img style="cursor: pointer;" @click="goTosubPage(item.url)" :src="item.image" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <!-- 今日特价 -->
      <div class="goods_box" v-if="specialList.length">
        <div class="box_title">
          <div class="title_left">
            <span class="title lineheight">今日特价</span>
            <span class="more" @click="goToPJExternalPage('/special/index')"></span>
          </div>
          <div class="title_right" id="jrtj" @click="changeClick(2)">
            <span>换一批</span>
            <span class="change"></span>
          </div>
        </div>
        <div class="box_list">
          <div class="goods_item" v-for="(item,index) in specialList" :key="item.goodsId" @click="pageJump('/goodsDetail?goodsId='+item.goodsId)">
            <!-- 商品图片区 -->
            <div class="goods_img">
              <img :src="item.thumbUrl" alt="">
              <img v-if="item.stock == 0" class="nostock" src="@/assets/images/index/nostock.png" alt="">
              <div class="tag">
                <span :style="item1.actType == 2||item1.actType == 4?'':'display: none'" :class="{tj:item1.actType == 2,yh:item1.actType == 4}" v-for="(item1,index1) in item.acts" :key="index1">{{item1.name}}</span>
              </div>
            </div>
            <!-- 商品信息区 -->
            <div class="goods_info">
              <div class="title">{{item.productName}}</div>
              <div class="spesc">
                <img src="@/assets/images/index/goods_gg.svg" alt="">
                <el-tooltip class="item" effect="dark" :disabled="item.norms&&item.norms.length<23" :content="item.norms" placement="top-start">
                  <span>{{item.norms}}</span>
                </el-tooltip>
              </div>
              <div class="spesc">
                <img src="@/assets/images/index/goods_cj.svg" alt="">
                <span>{{item.produceUnit?item.produceUnit:'--'}}</span>
              </div>
              <div class="spesc">
                <img src="@/assets/images/index/goods_xq.svg" alt="">
                <span>优于{{item.lastDate?item.lastDate:'--'}}</span>
              </div>
              <!-- <div class="price" v-html="initPrice(item.jiage)"></div> -->
                 <div style="display:flex;align-items:center;padding-left:10px;margin:10px 0 0px;">
                               <div class="price" v-html="initPrice(item.jiage)"></div>
                                 <div v-if="item.jiage!=item.price" style="font-size: 10px;color: #ACACAC;text-decoration-line: line-through;margin-left:5px;">原价{{item.price}}</div>
                               </div>
              <div class="gross">
                <span>毛利率 {{item.grossMargin?item.grossMargin:'--'}}</span>
                <span>零售价 ￥{{(item.retailPrice).toFixed(2)}}</span>
              </div>
            </div>
            <!-- 商品加购收藏区 -->
            <div class="goods_purchase" @click.stop="">
              <div class="purchase_top">
                <div class="collect" @click="favoriteClick(item.goodsId,specialList,index)">
                  <img v-if="item.favorite" src="@/assets/images/index/collect_a.svg" alt="">
                  <img v-else src="@/assets/images/index/collect.svg" alt="">
                </div>
                <el-input-number class="number" v-model="item.buyNumber" @change="addNumberChange(item)" :disabled="item.stock == 0" size="small" :min="item | minStep" :step="item | minStep" :precision="0"></el-input-number>
                <div v-if="item.cartNumber>0" class="add exist" :class="{disabled:item.stock == 0}" @click="addPurchase(item)">
                  <span>已在采购车</span>
                </div>
                <div v-else class="add" :class="{disabled:item.stock == 0}" @click="addPurchase(item)">
                  <span>加入采购车</span>
                </div>
              </div>
              <span>库存{{item.stock}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 采满有赠 -->
      <div class="goods_box" v-if="fullGiftsList.length">
        <div class="box_title">
          <div class="title_left">
            <span class="title lineheight">采满有赠</span>
            <span class="more" @click="goToPJExternalPage('/gifts/index')"></span>
          </div>
          <div class="title_right" id="cmyz" @click="changeClick(3)">
            <span>换一批</span>
            <span class="change"></span>
          </div>
        </div>
        <div class="box_list">
          <div class="goods_item" v-for="(item,index) in fullGiftsList" :key="item.goodsId" @click="pageJump('/goodsDetail?goodsId='+item.goodsId)">
            <!-- 商品图片区 -->
            <div class="goods_img">
              <img :src="item.thumbUrl" alt="">
              <img v-if="item.stock == 0" class="nostock" src="@/assets/images/index/nostock.png" alt="">
              <div class="tag">
                <span :style="item1.actType == 2||item1.actType == 4?'':'display: none'" :class="{tj:item1.actType == 2,yh:item1.actType == 4}" v-for="(item1,index1) in item.acts" :key="index1">{{item1.name}}</span>
              </div>
            </div>
            <!-- 商品信息区 -->
            <div class="goods_info">
              <div class="title">{{item.productName}}</div>
              <div class="spesc">
                <img src="@/assets/images/index/goods_gg.svg" alt="">
                <el-tooltip class="item" effect="dark" :disabled="item.norms&&item.norms.length<23" :content="item.norms" placement="top-start">
                  <span>{{item.norms}}</span>
                </el-tooltip>
              </div>
              <div class="spesc">
                <img src="@/assets/images/index/goods_cj.svg" alt="">
                <span>{{item.produceUnit?item.produceUnit:'--'}}</span>
              </div>
              <div class="spesc">
                <img src="@/assets/images/index/goods_xq.svg" alt="">
                <span>优于{{item.lastDate?item.lastDate:'--'}}</span>
              </div>
              <div class="price" v-html="initPrice(item.jiage)"></div>
              <div class="gross">
                <span>毛利率 {{item.grossMargin?item.grossMargin:'--'}}</span>
                <span>零售价 ￥{{(item.retailPrice).toFixed(2)}}</span>
              </div>
            </div>
            <!-- 商品加购收藏区 -->
            <div class="goods_purchase" @click.stop="">
              <div class="purchase_top">
                <div class="collect" @click="favoriteClick(item.goodsId,fullGiftsList,index)">
                  <img v-if="item.favorite" src="@/assets/images/index/collect_a.svg" alt="">
                  <img v-else src="@/assets/images/index/collect.svg" alt="">
                </div>
                <el-input-number class="number" v-model="item.buyNumber" @change="addNumberChange(item)" :disabled="item.stock == 0" size="small" :min="item | minStep" :step="item | minStep" :precision="0"></el-input-number>
                <div v-if="item.cartNumber>0" class="add exist" :class="{disabled:item.stock == 0}" @click="addPurchase(item)">
                  <span>已在采购车</span>
                </div>
                <div v-else class="add" :class="{disabled:item.stock == 0}" @click="addPurchase(item)">
                  <span>加入采购车</span>
                </div>
              </div>
              <span>库存{{item.stock}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 品牌独家 -->
      <div class="brand_box goods_box" v-for="item in indexFloorList" :key="item.floorName">
        <div class="brand_title">{{item.floorName}}</div>
        <div class="brand_cate">
          <div class="cate_item" :class="{active: item.cateIndex == index1 }" v-for="(item1,index1) in item.floorCate" @click="brandCateClick(item.floorName,index1)" :key="index1">{{item1.categoryName}}</div>
        </div>
        <div class="box_list brand_list">
          <div class="banner">
            <img @click="floorClick(item.id,item.floorName)" :src="item.backgroundImage" alt="">
          </div>
          <div class="goods_item" v-for="(item2,index2) in item.goodsList" :key="item2.goodsId" @click="pageJump('/goodsDetail?goodsId='+item2.goodsId)">
            <!-- 商品图片区 -->
            <div class="goods_img">
              <img :src="item2.thumbUrl" alt="">
              <img v-if="item2.stock == 0" class="nostock" src="@/assets/images/index/nostock.png" alt="">
              <div class="tag">
                <span :style="item1.actType == 2||item1.actType == 4?'':'display: none'" :class="{tj:item1.actType == 2,yh:item1.actType == 4}" v-for="(item1,index1) in item.acts" :key="index1">{{item1.name}}</span>
              </div>
            </div>
            <!-- 商品信息区 -->
            <div class="goods_info">
              <div class="title">{{item2.productName}}</div>
              <div class="spesc">
                <img src="@/assets/images/index/goods_gg.svg" alt="">
                <el-tooltip class="item" effect="dark" :disabled="item2.norms&&item2.norms.length<23" :content="item2.norms" placement="top-start">
                  <span>{{item2.norms}}</span>
                </el-tooltip>
              </div>
              <div class="spesc">
                <img src="@/assets/images/index/goods_cj.svg" alt="">
                <span>{{item2.produceUnit}}</span>
              </div>
              <div class="spesc">
                <img src="@/assets/images/index/goods_xq.svg" alt="">
                <span>优于{{item2.lastDate?item2.lastDate:'--'}}</span>
              </div>
              <div class="price" v-html="initPrice(item2.jiage)"></div>
              <div class="gross">
                <span>毛利率 {{item2.grossMargin?item2.grossMargin:'--'}}</span>
                <span>零售价 ￥{{(item2.retailPrice).toFixed(2)}}</span>
              </div>
            </div>
            <!-- 商品加购收藏区 -->
            <div class="goods_purchase" @click.stop="">
              <div class="purchase_top">
                <div class="collect" @click="favoriteClick(item2.goodsId,item.goodsList,index2)">
                  <img v-if="item2.favorite" src="@/assets/images/index/collect_a.svg" alt="">
                  <img v-else src="@/assets/images/index/collect.svg" alt="">
                </div>
                <el-input-number class="number" v-model="item2.buyNumber" @change="addNumberChange(item2)" :disabled="item2.stock == 0" size="small" :min="item2 | minStep" :step="item2 | minStep" :precision="0"></el-input-number>
                <div v-if="item2.cartNumber>0" class="add exist" :class="{disabled:item2.stock == 0}" @click="addPurchase(item2)">
                  <span>已在采购车</span>
                </div>
                <div v-else class="add" :class="{disabled:item2.stock == 0}" @click="addPurchase(item2)">
                  <span>加入采购车</span>
                </div>
              </div>
              <span>库存{{item2.stock}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCarNum } from '@/utils/carNumber'
export default {
  name: 'Index',
  data() {
    return {
      bannerHeight:'',
      noticeShow: true,
      price: 369.36,
      num: 1,
      bannerList: [],//轮播图列表
      noticeList: [],//公告列表
      indexTopicList: [],//专题广告图列表
      floorAdList: [],//夹层广告图列表
      indexFloorList: [],//首页楼层列表
      specialList: [],//今日特价列表
      fullGiftsList: [],//采满有赠列表
      specialInfo: {
        page:1,
        pageSize:5
      },
      fullGiftsInfo: {
        page:1,
        pageSize:5
      }
    };
  },
  filters: {
    minStep(item){
      let num = 1
      item.Pack.forEach(item1=>{
        if(item1.isPack == 'on'){
          num = item1.num
        }
      })
      return Number(num)
    }
  },
  mounted(){
    this.resetClick()
  },
  beforeDestroy() {
    // 取消事件监听器
    window.removeEventListener('resize', this.handleResize);
  },
  beforeRouteEnter(to, from, next) {
    // console.log(from.path)
    next((vm) => {
      if (from.path != '/goodsDetail') {
        vm.resetClick()
      }
    });
  },
  methods: {
    // 重置数据
    resetClick(){
      // 重置data数据
      Object.assign(this.$data, this.$options.data());
      // 获取轮播图
      this.getBannerList()
      // 获取公告
      this.getNoticeList()
      // 获取专题广告图
      this.getIndexTopicList()
      // 获取夹层广告图
      this.getFloorAdList()
      // 获取首页楼层
      this.getIndexFloor()
      // 获取今日特价列表
      this.getSpecialList(2)
      // 获取采满有赠列表
      this.getSpecialList(3)
    },
    // 拼接链接跳转
    goToPJExternalPage(url) {
      // console.log(url)
      // 将URL设置为你想要跳转的外部页面
      const loading = this.$loading();
      window.location.href = process.env.VUE_APP_TZURL + url;
      loading.close()
      // window.open(process.env.VUE_APP_TZURL + url)
    },
    goTosubPage(url){
      window.location.href=url
    },
    // 获取banner图高度
    imgLoad(height){
      this.$nextTick(()=>{
          // console.log(this.$refs.bannerHeight[0].clientHeight);
          this.bannerHeight=height?height:this.$refs.bannerHeight[0].height;
      })
    },
    // 关闭公告
    closeNotice(){
      this.noticeShow = false
    },
    handleResize(){
      setTimeout(() => {
        this.bannerHeight=this.$refs.bannerHeight[0].height;
        this.imgLoad();
      }, 16);
    },
    // 获取banner列表
    getBannerList(){
      this.$api.pcBanner().then((res) => {
        if (res.data.code === 200) {
          this.bannerList = res.data.data
          setTimeout(() => {
            this.imgLoad(this.$refs.bannerHeight[0].height);
            window.addEventListener('resize',this.handleResize,false)
          }, 16);
        }
      });
    },
    // 获取公告列表
    getNoticeList(){
      this.$api.pcAlone().then((res) => {
        if (res.data.code === 200) {
          this.noticeList = res.data.data
          if(this.noticeList&&this.noticeList.length){
            this.noticeShow = true
          }else{
            this.noticeShow = false
          }
        }
      });
    },
    // 获取专题广告图列表
    getIndexTopicList(){
      this.$api.pcIndexTopic().then((res) => {
        if (res.data.code === 200) {
          this.indexTopicList = res.data.data
        }
      });
    },
    // 获取夹层广告图列表
    getFloorAdList(){
      this.$api.pcFloorAd().then((res) => {
        if (res.data.code === 200) {
          this.floorAdList = res.data.data
        }
      });
    },
    // 获取特价列表
    getSpecialList(type,element){
      let params = {
        type,
        page: type==2?this.specialInfo.page:this.fullGiftsInfo.page,
        pageSize: type==2?this.specialInfo.pageSize:this.fullGiftsInfo.pageSize,
      }
      // 给这个元素添加一个类名 "new-class"
      if(element) element.classList.add("active");
      this.$api.pcSpecial(params).then((res) => {
        if(element){
          setTimeout(() => {
            element.classList.remove("active");
          }, 300);
        }
        if (res.data.code === 200) {
          if(type == 2){
            this.specialList = res.data.data.list//今日特价
            let page = Math.ceil(res.data.data.total/5)
            if(page == this.specialInfo.page) this.specialInfo.page = 0
          }
          if(type == 3){
            this.fullGiftsList = res.data.data.list//采满有赠
            let page = Math.ceil(res.data.data.total/5)
            if(page == this.fullGiftsInfo.page) this.fullGiftsInfo.page = 0
          }
        }
      });
    },
    // 获取首页楼层列表
    getIndexFloor(){
      this.$api.pcIndexFloor().then((res) => {
        if (res.data.code === 200) {
          this.indexFloorList = res.data.data
          this.indexFloorList.forEach(item=>{
            item.cateIndex=0
            item.goodsList=item.floorCate[0].goods
          })
        }
      });
    },
    // 楼层分类点击
    brandCateClick(name,index){
      this.indexFloorList.forEach(item=>{
        if(item.floorName == name){
          item.cateIndex=index
          item.goodsList=item.floorCate[index].goods
        }
      })
      this.indexFloorList = this.indexFloorList.filter(item=>item)
    },
    // 楼层banner点击
    floorClick(id,name){
      this.$router.push('/onlineOrder?floorId='+id+'&floorName='+name)
    },
    // 收藏点击
    favoriteClick(id,list,index){
      let params = {
        goodsId:id
      }
      this.$api.pcProductDoFav(params).then((res) => {
        if (res.data.code === 200) {
          list[index].favorite = list[index].favorite==true?false:true
          list = list.filter(item=>item)
          let msg= list[index].favorite?'收藏成功':'取消收藏成功'
          this.$toast(msg);
        }
      });
    },
    // 加购输入框限制
    addNumberChange(item){
      var number = 1
      item.Pack.forEach(item1=>{
        if(item1.isPack == 'on'){
          number = item1.num
        }
      })
      if(item.buyNumber%number != 0){
        this.$toast('加购数量必须为中包装数量倍数！');
        item.buyNumber = number
      }
    },
    // banner跳转
    goToExternalPage(url) {
      // 将URL设置为你想要跳转的外部页面
      // 页面跳转增加loading
      const loading = this.$loading();
      window.location.href = url;
      setTimeout(() => {
        loading.close()
      }, 1000);
      // window.open(url)
    },
    // 点击换一批
    changeClick(type){
      if(type == 2){
        // 今日特价
        this.specialInfo.page++
        var element = document.getElementById("jrtj");
      }else if(type == 3){
        // 采满有赠
        this.fullGiftsInfo.page++
        var element = document.getElementById("cmyz");
      }
      this.getSpecialList(type,element)
      
    },
    // 页面跳转
    pageJump(url){
      this.$router.push(url)
    },
    // 加入采购车
    addPurchase(item){
      if(item.stock == 0){
        this.$toast('该商品即将到货！');
      }else{
        let params = {
          goodsId: item.goodsId,
          num: item.buyNumber,
          actType : 1,
          actId: 0,
        }
        this.$api.pcJoinCar(params).then((res) => {
          if (res.data.code === 200) {
            this.$toast(res.data.data.message);
            if(res.data.data.state === 200){
              // 成功加入采购车后按钮状态变成已在采购车
              item.cartNumber = item.buyNumber
            }
            // 更新采购车数量
            getCarNum(this)
          }
        });
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.banner_box{
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  ::v-deep{
    .el-carousel__button{
      width: 15px;
      height: 6px;
      background: #FFFFFF;
      border-radius: 100px 100px 100px 100px;
      opacity: 1;
    }
    .el-carousel__indicator.is-active button{
      width: 26px;
      height: 6px;
      background: #0687FE;
      border-radius: 100px 100px 100px 100px;
    }
    .el-carousel__arrow--left,.el-carousel__arrow--right{
      background-color: rgba(0, 0, 0, .3);
      font-size: 18px;
    }
    .el-carousel__arrow:hover,.el-carousel__arrow:hover{
      background-color: rgba(0, 0, 0, .2);
    }
    .el-icon-arrow-left,.el-icon-arrow-right{
      font-weight: 600;
    }
  }

  .type_area{
    position: relative;

    .notice_box{
      position: absolute;
      z-index: 4;
      top: 8px;
      left: 56px;
      width: 1200px;
      height: 40px;
      display: flex;
      align-items: center;
      background: rgba(245, 248, 255, 0.8);
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #F6F6F6;
      font-size: 14px;
      color: #313337;

      .info{
        color: #B2B7BF;
        margin-left: 14px;
        font-size: 16px;
      }

      .close{
        position: absolute;
        right: 12px;
        top: 10px;
        color: #9BAAB5;

        ::v-deep{
          .el-icon-close{
            font-weight: 600 !important;
          }
        }

        &:hover{
          color: #1A9DFF;
          cursor: pointer;
        }
      }
    }
  }
}
.index_box{

  .advert_box{
    margin-bottom: 20px;
    .advert_top{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;

      .advert_item img{
        width: 390px;
        height: 260px;
        border-radius: 6px;
        cursor: pointer;
      }
    }
    .advert_bottom{
      border-radius: 2px;
      overflow: hidden;
      
      ::v-deep{
        .el-carousel__arrow--left{
          left: 0;
          width: 30px;
          border-radius: 0 100px 100px 0;
          background-color: rgba(0, 0, 0, .3);
          font-size: 18px;
        }
        .el-carousel__arrow:hover,.el-carousel__arrow:hover{
          background-color: rgba(0, 0, 0, .2);
        }
        .el-carousel__arrow--right{
          right: 0;
          width: 30px;
          border-radius: 100px 0 0 100px;
          background-color: rgba(0, 0, 0, .3);
          font-size: 18px;
        }
        .el-icon-arrow-left,.el-icon-arrow-right{
          font-weight: 600;
        }
      }

      .advert_item{
        width: 100%;
        height: 106px;
        background: #ccc;
      }
    }
  }

  .goods_box{
    margin-bottom: 12px;

    &.brand_box{
      .brand_title{
        font-weight: bold;
        font-size: 28px;
        color: #333333;
        padding: 0 0 20px;
        text-align: center;
      }
      .brand_cate{
        display: flex;
        align-items: center;
        padding: 15px 26px;
        background: #FFFFFF;
        border-radius: 2px 2px 2px 2px;
        margin-bottom: 12px;

        .cate_item{
          padding: 6px 14px;
          font-weight: bold;
          font-size: 14px;
          color: #333333;
          border-radius: 100px;
          margin-right: 13px;
          cursor: pointer;

          &.active,&.active:hover{
            background: #1A9DFF;
            color: #fff;
          }
          &:hover{
            color: #1A9DFF;
          }
        }
      }
      .brand_list{
        .banner{
          margin-right: 12px;
          margin-bottom: 12px;
          img{
            width: 715px;
            height: 315px;
            border-radius: 2px 2px 2px 2px;
            vertical-align: middle;
          }
        }
        .goods_item{
          &:nth-of-type(3){
            margin-right: 0;
          }
        }
      }
    }
    .box_title{
      height: 62px;
      padding: 0 20px;
      background: #FFFFFF;
      border-radius: 2px 2px 2px 2px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 13px;

      .title_left{
        display: flex;
        align-items: center;

        .title{
          font-weight: bold;
          font-size: 26px;
          color: #333333;
        }

        .more{
          width: 28px;
          height: 28px;
          display: inline-block;
          margin-top: 2px;
          margin-left: 8px;
          background-image: url("~@/assets/images/index/more.svg");

          &:hover{
            transition: 0.3s;
            cursor: pointer;
            background-image: url("~@/assets/images/index/more_a.svg");
          }
        }
      }

      .title_right{
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        color: #333333;

        @keyframes scroll {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        .change{
          width: 15px;
          height: 15px;
          display: inline-block;
          margin-top: 2px;
          margin-left: 5px;
          background-image: url("~@/assets/images/index/change.svg");
          background-size: 100%;
        }
        
        &.active{
          color: #409EFF;
          transition: 0.3s;
          cursor: pointer;

          .change{
            background-image: url("~@/assets/images/index/change_a.svg");
            animation: scroll 1s linear infinite;
          }
        }
        &:hover{
          color: #409EFF;
          transition: 0.3s;
          cursor: pointer;

          .change{
            background-image: url("~@/assets/images/index/change_a.svg");
          }
        }
      }
    }

    .box_list{
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .goods_item{
        position: relative;
        width: 230px;
        height: 314px;
        box-sizing: border-box;
        margin: 0 12px 12px 0;
        background: #FFFFFF;
        border-radius: 2px 2px 2px 2px;

        &:hover{
          .goods_purchase{
            opacity: 1;
            visibility: visible;
          }
        }

        &:last-of-type{
          margin-right: 0;
        }

        .goods_img{
          position: relative;
          text-align: center;
          margin-bottom: 4px;

          .nostock{
            width: 132px;
            height: 132px;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
          }

          img{
            width: 160px;
            height: 160px;
            vertical-align: middle;
          }

          .tag{
            position: absolute;
            left: 10px;
            bottom: 0;
            display: flex;
            align-items: center;

            span{
              display: flex;
              align-items: center;
              justify-content: center;
              width: 33px;
              height: 21px;
              box-sizing: border-box;
              border-radius: 3px 3px 3px 3px;
              font-size: 12px;
              line-height: 0px;
            }

            .tj{
              background: rgba(248, 213, 213, 0.9);
              border: 1px solid #FFACAC;
              color: #F92B2B;
              margin-right: 4px;
            }
            .zy{
              background: rgba(206, 231, 249, 0.9);
              border: 1px solid #B7DCF8;
              color: #1A9DFF;
            }
            .yh{
              width: auto;
              padding: 0 6px;
              background: rgba(248, 240, 223, .9);
              border: 1px solid #FAD894;
              color: #F7A400;
            }
          }
        }

        .goods_info{
          padding: 0 10px;

          .title{
            font-weight: bold;
            font-size: 16px;
            color: #333333;
            margin-bottom: 6px;
            white-space: nowrap; /* 不换行 */
            overflow: hidden; /* 超出部分隐藏 */
            text-overflow: ellipsis; /* 溢出部分显示省略号 */
          }
          .spesc{
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #666666;
            margin-bottom: 5px;
            
            span{
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            img{
              margin-right: 3px;
            }
          }
          .invent{
            color: #666666;
          }
          .price{
            color: #F34141;
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 3px;
          }
          .gross{
            display: inline-block;
            padding: 6px;
            background: #ECF6FF;
            border-radius: 2px 2px 2px 2px;
            font-size: 12px;
            color: #4E6474;
            line-height: 0px;

            span{
              &:first-of-type::after{
                content: "";
                display: inline-block;
                width: 1px;
                height: 10px;
                background: #C4D4E2;
                margin: 0 6px 0 9px;
              }
            }
          }
        }

        .goods_purchase{
          opacity: 0;
          visibility: hidden;
          transition: 0.5s;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          box-sizing: border-box;
          padding: 8px 10px;
          background: rgba(0, 0, 0, 0.3);
          font-size: 12px;
          color: #FFFFFF;

          .purchase_top{
            display: flex;
            align-items: center;
            margin-bottom: 8px;

            .collect{
              width: 30px;
              height: 30px;
              box-sizing: border-box;
              margin-right: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #F5F7FA;
              border-radius: 4px 4px 4px 4px;
              border: 1px solid #FFFFFF;
              cursor: pointer;
            }
            .number{
              width: 80px;
              height: 30px;
              margin-right: 8px;
              margin-top: -3px;

              ::v-deep{
                .el-input__inner{
                  height: 30px;
                  line-height: 30px;
                  background: #F5F7FA;
                  border: 1px solid #fff;
                  color: #333;
                  font-weight: 600;
                  font-size: 15px;
                }
                .el-input-number__decrease, .el-input-number__increase{
                  width: 20px;
                  top: 0;
                  height: 28px;
                  background: #ECEDF0;
                  border: 1px solid #fff;
                  color: #333;
                }
                .el-icon-plus,el-icon-minus{
                  font-weight: 600;
                }
                .el-input-number__decrease.is-disabled, .el-input-number__increase.is-disabled{
                  background: #E1E4E9;
                }
                .el-input__inner{
                  padding-left: 22px;
                  padding-right: 22px;
                }
              }
            }

            .add{
              width: 86px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: linear-gradient( 181deg, #1A9DFF 0%, #0687FE 100%);
              border-radius: 4px 4px 4px 4px;
              font-size: 14px;
              color: #FFFFFF;

              &:hover{
                background: linear-gradient( 181deg, #4DAEF6 0%, #0687FE 100%);
                cursor: pointer;
                transition: 0.3s;
              }
              &.disabled,&.disabled:hover{
                background: linear-gradient( 181deg, #86C8F9 0%, #7CBDF9 100%);
              }

              &.exist{
                background: linear-gradient( 182deg, #31C19E 0%, #14AC87 100%);

                &:hover{
                  background: linear-gradient( 182deg, #14AC87 0%, #31C19E 100%);
                  cursor: pointer;
                  transition: 0.3s;
                }
                &.disabled,&.disabled:hover{
                  background: linear-gradient( 182deg, #91DAC8 0%, #89D6C3 100%);
                }
              }
            }
          }
        }
      }
    }
    
  }
}
</style>